import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client, linkResolver } from "../../prismic-configuration";
import { PrismicRichText } from '@prismicio/react';
import BasePadding from "../BasePadding";
import Container from "../Container";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import styles from "./Footer.module.scss";
import formstyles from "../Form/Form.module.scss";
import Cta from "../Cta";

const Footer = () => {
	const [footerData, setFooterData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getSingle("footer");

			if (result) {
				return setFooterData(result.data);
			}
		}
		fetchData();
	}, []);

	return (
		<footer className={styles.footer}>
			<Container>
					<BasePadding>
						<div className={styles.footerTop}>
							{footerData?.show_newsletter_signup &&
								<div className={styles.newsletterSignup}>
									<span>Subscribe to our newsletter</span>
									<form action="https://usebasin.com/f/4c73e4eeb1d1" method="POST">
										<Stack width="100%" spacing={1.5} mb={1} className={formstyles.inputWrapper}>
											<TextField type="text" id="linkedin" name="linkedin" label="LINKEDIN" required={true} />
											<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required={true} />
											<Cta type="button">
												<input type="submit" value="submit" />
											</Cta>
										</Stack>
									</form>
								</div>
							}
							{footerData?.footer_links &&
								<div className={styles.footerLinks}>
									<ul>
										{footerData.footer_links.map((linkItem, index) => (
											<li key={index}>
												{linkItem?.link?.uid
													?
														<Link to={linkItem.link.uid === "homepage" ? "/" : `/${linkItem.link.uid}`}>
															{linkItem.link_name}
														</Link>
													:
													<span>{linkItem.link_name}</span>
												}
											</li>
										))}
									</ul>
								</div>
							}
						</div>
						<div className={styles.footerBottom}>
						{footerData?.social_media_links &&
							<div className={styles.socialLinks}>
								{footerData.social_media_links.map((linkItem, index) => (
									<a key={index} href={linkItem.link.url} className={styles.socialLink} target="_blank" rel="noopener noreferrer"><img src={linkItem.icon.url} alt={linkItem.icon.alt} /></a>
								))}
							</div>
						}

							{footerData?.contact_info &&
								<div className={styles.contactInfo}>
									{footerData.contact_info.map((item, index) => (
										<div key={index} className={styles.contactItem}>
											<PrismicRichText field={item.text} linkResolver={linkResolver} />
										</div>
									))}
								</div>
							}
						</div>

						{footerData?.accreditation &&
							<div className={styles.accreditation}>
								{footerData.accreditation.map((linkItem, index) => (
									<img key={index} src={linkItem.logo.url} alt={linkItem.logo.alt} />
								))}
							</div>
						}
					</BasePadding>
			</Container>
		</footer>
	);
}

export default Footer;
