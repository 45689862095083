import React, { useEffect, useRef } from "react";
import { PrismicRichText } from '@prismicio/react';
import useSound from "use-sound";
import TextLoop from "react-text-loop";
import { Link } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Slider from "react-slick";
import qs from 'qs';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { CheckIcon } from "@radix-ui/react-icons";
import { TextareaAutosize } from '@mui/material';

import { ReactComponent as ArrowLeft } from "./resources/arrow-left.svg";

import { ReactComponent as ArrowDown } from "./resources/arrow-down.svg";
import BasePadding from "../BasePadding";
import Container from "../Container";
import PlayButton from "../PlayButton";
import Cta from "../Cta";

import styles from "./Hero.module.scss";
import formstyles from '../Form/Form.module.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { linkResolver } from "../../prismic-configuration";
import { GatedContentForm } from "./GatedContentForm";

const fileType = file => { return file ? file.match(/\.[0-9a-z]+$/i) : null };

const autoCompleteOptions = [
	"The Influencer",
	"Media Roundtable Podcast",
	"Other Podcast",
	"Linkedin (Post)",
	"Online article",
	"Newsletter",
	"Google or other search engine",
	"From a network partner",
	"I am a media vendor",
	"Another client referred me",
	"I worked at a previous client of Oxford Road",
	"Referral from Oxford Road team member",
	"Google (Ad)",
	"Linkedin (Ad)",
	"Friend/Colleague referral",
	"News/press",
	"Another ad agency"
];

const Hero = ({ background, fallbackImage, audio, heroTitle, text, heroCarousel, heroAlignment, showArrow, showBottomBorder, textColor, isHomepage, cta_link, cta_text, content_data }) => {
	const heroFileType = fileType(background.name);
	const [play, { stop, isPlaying }] = useSound(audio?.url);
	const audioFileType = fileType(audio?.name);
	const playerRef = useRef();
	const params = qs.parse(location.search.slice(1));

	useEffect(() => {
		if (heroFileType && heroFileType[0] === ".mp4") {
			playerRef.current.load();
		}
		return;
	}, [background]);

	const playClip = () => {
		play();
	};

	const stopClip = () => {
		stop();
	};

	const settings = {
		dots: false,
		fade: true,
		arrows: false,
		infinite: true,
		speed: 300,
		delay: 100,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		draggable: false,
		adaptiveHeight: false
	};

	return (
		<section
			id="hero"
			className={`
				${styles.hero}
				${styles[`align${heroAlignment}`]}
				${showBottomBorder ? styles.borderBottom : ""}
				${styles[`textColor${textColor}`]}
		`}>

			<div className={styles.background}>
				{heroFileType && heroFileType[0] === ".mp4"
					?
						<video ref={playerRef} autoPlay muted loop playsInline>
							<source src={background.url} type="video/mp4" />
						</video>
					:
						<img src={background.url} />
				}
				{fallbackImage?.url && <img className={styles.fallbackImage} src={fallbackImage.url} />}
			</div>

			{heroCarousel && content_data ?
				<Slider className={`${styles.carousel_box} carousel_box_wrapper`} {...settings}>
				{heroCarousel.map((hero, index) => (
					<div key={index} className={styles.carousel_box_item} >
						<img  className={styles.carousel_box_item} src={hero.hero_background_carousel_item.url} alt={'img'} />
					</div>
				))}
				</Slider>
				:
				<></>
			}

			<Container>
				<BasePadding>
					<div className={`${styles.overlay} ${content_data?.text_background_color ? styles[`textBackground${content_data?.text_background_color}`] : ''}`}>
						{heroCarousel ?
							<div className={styles.heroCarousel}>
								<h1>{heroTitle[0].text}{" "}</h1>
								<h1><TextLoop springConfig={{ damping: 40, stiffness: 300}}  noWrap={true}>
									{heroCarousel.map((hero, index) => (
										<strong key={index}>{hero.carousel_title[0].text}</strong>
									))}
								</TextLoop></h1>
							</div>
						:
							<div className={styles.heading}>
								<PrismicRichText field={heroTitle} />
							</div>
						}
						<div className={isHomepage ? styles.textHomepage : styles.text}>
							<PrismicRichText field={text} />
						</div>

						{content_data?.hero_button_label &&
							<a className={styles.hero_button} href={linkResolver(content_data?.hero_button_link)}>
								{content_data?.hero_button_label}
								<ArrowLeft />
							</a>
						}

						<div className={styles.cta}>
							{cta_link?.slug &&
								<div className={styles.cta}>
									<Cta type="button">
										<Link to={`/${cta_link.slug}`}>
											{cta_text}
										</Link>
									</Cta>
								</div>
							}
							{cta_link?.url &&
								<div className={styles.cta}>
									<Cta type="button">
										<a href={cta_link.url}>
											{cta_text}
										</a>
									</Cta>
								</div>
							}
						</div>
						<div className={styles.heroBottom}>
							{showArrow &&
								<ArrowDown className={styles.arrowDown} />
							}
							{isHomepage && audioFileType && (audioFileType[0] === (".mp3" || ".m4a")) &&
								<>
									<PlayButton
										active={isPlaying}
										play={playClip}
										stop={stopClip}
									/>
								</>
							}
						</div>
					</div>
					{content_data?.form && (
						<div className={`${formstyles.slimForm} ${styles.contentRight} ${styles.downloadForm} ${Object.keys(params).includes("redirect") ? styles.solidBackground : ''}`}>
							{Object.keys(params).includes("thanks") ? (
								<>
									<h4>Thank you!</h4>
								</>
							) : (
								<>
									{content_data?.form_cta_label && content_data?.form_cta_link?.slug && (
										<div className={styles.cta} style={{ marginBottom: '1rem' }}>
											<Cta type="button">
												<Link to={`/${content_data?.form_cta_link?.slug}`}>
													{content_data.form_cta_label}
												</Link>
											</Cta>
										</div>
									)}
									<form action={`https://usebasin.com/f/${content_data.form_slug}`} method="POST">
											{content_data.form === 'Audio Ads' && (
												<>
													<Stack spacing={1.5} mb={3} className={formstyles.inputWrapper}>
														<TextField type="text" id="name" label="FULL NAME" name="name" required="required" />
														<TextField type="text" id="company" label="COMPANY NAME" name="company" required="required" />
														<TextField type="text" id="title" label="JOB TITLE" name="job-title" />
														<TextField type="email" id="email" label="EMAIL ADDRESS" name="email" required="required" />
														<label className={`${formstyles.Label} ${formstyles.defaultLabel}`} for="how-can-we-help">How can we help you?</label>
														<TextareaAutosize id="how-can-we-help" name="how-can-we-help" required="required" />
														<div className={`${formstyles.Label} ${formstyles.defaultLabel}`}>
															Are you currently running audio ads?
														</div>
														<div className={formstyles.flex}>
															<RadioGroup.Root className={formstyles.RadioGroupRoot} name="currently-running-ads" defaultValue="yes">
																<Stack spacing={1.5}>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<RadioGroup.Item className={formstyles.RadioGroupItem} value="yes" id="running-ads-yes">
																		<RadioGroup.Indicator className={formstyles.RadioGroupIndicator} />
																		</RadioGroup.Item>
																		<label className={`${formstyles.Label} ${formstyles.defaultLabel}`} htmlFor="running-ads-yes">
																			Yes
																		</label>
																	</div>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<RadioGroup.Item className={formstyles.RadioGroupItem} value="no" id="running-ads-no">
																		<RadioGroup.Indicator className={formstyles.RadioGroupIndicator} />
																		</RadioGroup.Item>
																		<label className={`${formstyles.Label} ${formstyles.defaultLabel}`} htmlFor="running-ads-no">
																			No
																		</label>
																	</div>
																</Stack>
															</RadioGroup.Root>
														</div>
													</Stack>
													<Cta type="button">
														<input type="submit" />
													</Cta>
												</>
											)}
											{content_data.form === 'CAO Signup' && (
												<>
													<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
														<TextField type="text" id="name" label="FULL NAME" name="name" required="required" />
														<TextField type="email" id="email" label="EMAIL ADDRESS" name="email" required="required" />
														<TextField type="text" id="company" label="COMPANY NAME" name="company" required="required" />
														<TextField type="text" id="title" label="JOB TITLE" name="job-title" />
														<TextField type="text" id="budget" label="CURRENT ANNUAL AUDIO BUDGET" name="budget" />
														<Autocomplete
															id="how-did-you-hear-about-us"
															name="how-did-you-hear-about-us"
															required
															openOnFocus={false}
															freeSolo
															options={autoCompleteOptions.map(option => option)}
															renderInput={(params) => <TextField {...params} label="HOW DID YOU HEAR ABOUT US?" name="how-did-you-hear-about-us" />}
														/>
														<div className={formstyles.flex}>
															<Checkbox.Root className={formstyles.CheckboxRoot} defaultChecked id="newsletter-signup" name="newsletter-signup">
																<Checkbox.Indicator className={formstyles.CheckboxIndicator}>
																	<CheckIcon />
																</Checkbox.Indicator>
															</Checkbox.Root>
															<label className={`${formstyles.Label} ${formstyles.defaultLabel}`} htmlFor="newsletter-signup">
																Sign up for our FREE weekly newsletter, The Influencer!
															</label>
														</div>
													</Stack>
													<Cta type="button">
														<input type="submit" />
													</Cta>
												</>
											)}
									</form>
								</>
							)}
						</div>
					)}
					{content_data?.pdf_download?.url && <GatedContentForm {...content_data} />}
				</BasePadding>
			</Container>
		</section>
	)
};

export default Hero;
