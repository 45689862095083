import React, { useRef, useState, useEffect } from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";

import styles from "./ImageColumns.module.scss";
import Container from "../Container";
import BasePadding from "../BasePadding";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";

const ImageColumns = ({ addPadding, content: { primary, items }}) => {
	const [ displaySlides, setDisplaySlides ] = useState([]);
	const [ isTransitioning, setIsTransitioning ] = useState(0);
	const timer = useRef();

	useEffect(() => {
		setDisplaySlides(items.slice(0, 6));
	}, [items]);


	return (
		<div className={`
			${styles.logoSlider}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					<div className={`${styles.content} ${styles.logosOnly}`}>
						<div className={styles.copy}>
							{primary?.eyebrow  && <Eyebrow eyebrow={primary.eyebrow[0]?.text} />}
							<div className={styles.text}>
								<PrismicRichText field={primary.text1} />
								{primary.cta_link?.slug &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<Link to={`/${primary.cta_link.slug}`}>{primary.cta_text}</Link>
										</Cta>
									</div>
								}
								{primary.cta_link?.url &&
									<div className={styles.footer}>
										<Cta type="button" color={primary.background_color}>
											<a href={primary.cta_link.url}>{primary.cta_text}</a>
										</Cta>
									</div>
								}
							</div>
						</div>
						<div className={styles.logos}>
							{displaySlides.map((item, index) => (

								<div className={styles.logo}>
									<img src={item.image.url} alt={item.image.alt} />
								</div>
							))}
						</div>
					</div>
				</BasePadding>
			</Container>
		</div>
	);
};

export default ImageColumns;
