// - Name
// - Title
// - Email
// - Company
// - Brief Description of the Opportunity  (Short text)

import React from "react";
import styles from "./Form.module.scss";
import Cta from "../Cta";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';

const Media = props => (
	<form action="https://usebasin.com/f/fdbae06ad57d" method="POST">
		<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
			<TextField type="text" id="name" name="name" label="NAME" required="required" />
			<TextField type="text" id="company" name="company" label="COMPANY" required="required" />
			<TextField type="text" id="title" name="title" label="JOB TITLE" required="required" />
			<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
			<label for="opportunity">Brief Description of the Opportunity</label>
			<TextareaAutosize id="opportunity" name="opportunity" required="required" />
			<Cta type="button" color={props.background_color}>
				<input type="submit" />
			</Cta>
		</Stack>
	</form>
);

export default Media;
