import React from "react";
import { PrismicRichText } from '@prismicio/react';
import ReactPlayer from 'react-player'

import BasePadding from "../BasePadding";
import Container from "../Container";
import styles from "./YoutubePlaylist.module.scss";
import Eyebrow from "../Eyebrow";

const YoutubePlaylist = ({ isPost, content: { primary, items }}) => {
	return (
		<div
			style={{ scrollMarginTop: '75px' }}
			className={`
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
				${styles.main_wrapper}
			`}>
			<Container>
				<div className={styles.wrapper}>
					<div className={styles.column}>
						<BasePadding>
							{primary.text1[0]?.text && (
								<>
									{primary.eyebrow[0]?.text && <Eyebrow eyebrow={primary.eyebrow[0]?.text}/>}
									<div className={styles.textModule}>
										<PrismicRichText field={primary.text1}/>
									</div>
								</>
							)}
						</BasePadding>
					</div>
					<div className={styles.column}>
						<div className={styles.video_box}>
							{items.length && (
								<ReactPlayer width='100%' height='100%' url={
									items.map((item) => item.youtube_link.embed_url)
								}/>
								)}
						</div>
					</div>
				</div>
			</Container>
		</div>
		);
};

export default YoutubePlaylist;
