import React from "react";
import {
	Route,
	Routes
} from "react-router-dom";
import ReactGA from 'react-ga4';
// import { createBrowserHistory } from "history";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import Page from "./containers/Page";
import Blog from "./containers/Blog";

/**
 * Main application component
 */

const App = () => {
  ReactGA.initialize([
	{
		trackingId: "GTM-ML6G243",
	},
	{
	  trackingId: "355553449",
	}
  ]);
  
  return (
    <div className="App">
			<Navigation />
      <main>
        <Routes>
				<Route index element={<Page />} />
          <Route path="/thought-leadership/:uid" element={<Blog />} />
          <Route path="/:uid" element={<Page />} />
        </Routes>
      </main>
      <Footer />
    </div>
  )
}

export default App
