import React, { useState } from "react";
import * as RadioGroup from '@radix-ui/react-radio-group';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from "@radix-ui/react-icons";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/material';
import qs from 'qs';
import Cta from "../Cta";
import BasePadding from "../BasePadding";
import Container from "../Container";
import styles from '../Form/Form.module.scss';

const GatedForm = ({ addPadding, content: { primary }}) => {
	const params = qs.parse(location.search.slice(1));
    const [showPrimaryForm, setShowPrimaryForm] = useState(false);
    const [showSecondaryForm, setShowSecondaryForm] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [checkboxHasValue, setCheckboxHasValue] = useState({
		"welcome-reception": false,
		"daytime-summit": false,
		"nighttime-summit": false,
		"unable-to-attend": false
	});
	const [error, showError] = useState(false);
	const [showTextArea, setShowTextArea] = useState(false);

	const atLeastOneChecked = Object.values(checkboxHasValue).includes(true);

	const handleSubmission = () => {
		if (inputValue === primary.keyword) {
			setShowPrimaryForm(true);
		} else if (inputValue === primary.secondary_password) {
			setShowSecondaryForm(true);
		} else {
			showError(true);
		}
	}
	return (
		<div className={`
			${addPadding ? styles.paddingTop : ""}
			${styles.paddingBottom}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					{Object.keys(params).includes("thanks") ? (
						<div className={styles.centerAligned}>
							<h1>Thanks!</h1>
							<p className={styles.strong}>We look forward to seeing you in July</p>
						</div>
					): (
					<>
					{showPrimaryForm && (
						<div className={`${styles.slimForm} ${styles.lightForm}`}>
							<p className={styles.leadText}>Fill out the form below to finish registration</p>
							<form action="https://usebasin.com/f/90d409e97383" method="POST">
								<Stack spacing={1.5} mb={1} className={`${styles.inputWrapper} ${styles.lightInputs}`}>
									<TextField type="text" id="name" name="name" label="NAME" required="required" />
									<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
									<TextField type="text" id="company" name="company" label="COMPANY NAME" required="required" />
									<TextField type="text" id="title" name="title" label="JOB TITLE" />
									<label for="dietary-restrictions">Dietary Restrictions</label>
									<TextareaAutosize id="dietary-restrictions" name="dietary-restrictions" />
								</Stack>
								<div className={`${styles.input} ${styles.textDark}`}>
									<p className={styles.strong}>Please confirm you’re able to attend all 3 events.</p>
									<p>(Please note that rooms will be allocated on a first-come, first-served basis, with priority given to guests who are able to attend all three events).</p>
									<div className={styles.flex}>
										<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"welcome-reception": checked
											})}
											className={styles.CheckboxRoot}
											id="welcome-reception"
											name="welcome-reception"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="welcome-reception">
										Welcome Reception (July 12th | 6-9pm)
										</label>
									</div>
									<div className={styles.flex}>
										<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"daytime-summit": checked
											})}
											className={styles.CheckboxRoot}
											id="daytime-summit"
											name="daytime-summit"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="daytime-summit">
											Daytime Summit (July 13th | 9am-4pm)
										</label>
									</div>
									<div className={styles.flex}>
										<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"nighttime-summit": checked
											})}
											className={styles.CheckboxRoot}
											id="nighttime-summit"
											name="nighttime-summit"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="nighttime-summit">
											Nighttime Summit (July 13th | 6-9pm)
										</label>
									</div>
									<div className={styles.flex}>
										<Checkbox.Root onCheckedChange={checked => {
												setCheckboxHasValue({
													...checkboxHasValue,
													"unable-to-attend": checked
												})
												setShowTextArea(checked)
											}}
											className={styles.CheckboxRoot}
											id="unable-to-attend"
											name="unable-to-attend"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="unable-to-attend">
											Unable to attend
										</label>
									</div>
									{showTextArea && (
										<div style={{ marginTop: '1rem' }} className={`${styles.inputWrapper} ${styles.lightInputs}`}>
											<label for="comments">Any comments for the host</label>
											<TextareaAutosize id="comments" name="comments" />
										</div>
									)}
								</div>
								<hr className={styles.hr} />
								<div className={`${styles.input} ${styles.textDark}`}>
									<p className={styles.strong}>Please click here to confirm your complimentary 2-night hotel accommodations at the Historic Culver Hotel</p>
									<RadioGroup.Root className={styles.RadioGroupRoot} id="needs-accommodations" name="needs-accommodations" required>
										<div className={styles.flex}>
											<RadioGroup.Item className={styles.RadioGroupItem} value="yes" id="needs-accommodations-yes">
												<RadioGroup.Indicator className={styles.RadioGroupIndicator} />
											</RadioGroup.Item>
											<label className={styles.Label} htmlFor="needs-accommodations-yes">
												Yes
											</label>
										</div>
										<div className={styles.flex}>
											<RadioGroup.Item className={styles.RadioGroupItem} value="no" id="needs-accommodations-no">
												<RadioGroup.Indicator className={styles.RadioGroupIndicator} />
											</RadioGroup.Item>
											<label className={styles.Label} htmlFor="needs-accommodations-no">
												No
											</label>
										</div>
									</RadioGroup.Root>
								</div>
								<Cta type="button">
									<input type="submit" disabled={!atLeastOneChecked} />
								</Cta>
							</form>
						</div>
					)}
					{showSecondaryForm && (
						<div className={`${styles.slimForm} ${styles.lightForm}`}>
							<p className={styles.leadText}>Fill out the form below to finish registration</p>
							<form action="https://usebasin.com/f/f6b602a07254" method="POST">
								<Stack spacing={1.5} mb={1} className={`${styles.inputWrapper} ${styles.lightInputs}`}>
									<TextField type="text" id="name" name="name" label="NAME" required="required" />
									<TextField type="email" id="email" name="email" label="EMAIL ADDRESS" required="required" />
									<TextField type="text" id="company" name="company" label="COMPANY NAME" required="required" />
									<TextField type="text" id="title" name="title" label="JOB TITLE" />
									<label for="dietary-restrictions">Dietary Restrictions</label>
									<TextareaAutosize id="dietary-restrictions" name="dietary-restrictions" />
								</Stack>
								<div className={`${styles.input} ${styles.textDark}`}>
									<p className={styles.strong}>Please confirm you’re able to attend all 3 events.</p>
									<div className={styles.flex}>
										<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"welcome-reception": checked
											})}
											className={styles.CheckboxRoot}
											id="welcome-reception"
											name="welcome-reception"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="welcome-reception">
										Welcome Reception (July 12th | 6-9pm)
										</label>
									</div>
									<div className={styles.flex}>
										<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"daytime-summit": checked
											})}
											className={styles.CheckboxRoot}
											id="daytime-summit"
											name="daytime-summit"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="daytime-summit">
											Daytime Summit (July 13th | 9am-4pm)
										</label>
									</div>
									<div className={styles.flex}>
									<Checkbox.Root
											onCheckedChange={checked => setCheckboxHasValue({
												...checkboxHasValue,
												"nighttime-summit": checked
											})}
											className={styles.CheckboxRoot}
											id="nighttime-summit"
											name="nighttime-summit"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="nighttime-summit">
											Nighttime Summit (July 13th | 6-9pm)
										</label>
									</div>
									<div className={styles.flex}>
										<Checkbox.Root onCheckedChange={checked => {
												setCheckboxHasValue({
													...checkboxHasValue,
													"unable-to-attend": checked
												})
												setShowTextArea(checked)
											}}
											className={styles.CheckboxRoot}
											id="unable-to-attend"
											name="unable-to-attend"
										>
											<Checkbox.Indicator className={styles.CheckboxIndicator}>
												<CheckIcon />
											</Checkbox.Indicator>
										</Checkbox.Root>
										<label className={styles.Label} htmlFor="unable-to-attend">
											Unable to attend
										</label>
									</div>
									{showTextArea && (
										<div className={`${styles.inputWrapper} ${styles.lightInputs}`}>
											<label for="comments">Any comments for the host</label>
											<TextareaAutosize id="comments" name="comments" />
										</div>
									)}
								</div>
								<Cta type="button">
									<input type="submit" disabled={!atLeastOneChecked} />
								</Cta>
							</form>
						</div>
					)}
					{!showPrimaryForm && !showSecondaryForm && (
						<>
							<p className={styles.leadText}>This event is by invite only, enter your password below to register</p>
							{error && <p className={styles.error}>Incorrect password</p>}
							<form onSubmit={e => {e.preventDefault(); handleSubmission()}} className={styles.inlineForm}>
								<div className={styles.input}>
									<input id="password" value={inputValue} onChange={e => setInputValue(e.target.value)} placeholder="ENTER PASSWORD" />
								</div>
								<Cta type="button">
									<input type="submit" />
								</Cta>
							</form>
						</>
					)}
					</>
					)}
				</BasePadding>
			</Container>
		</div>
	);
};

export default GatedForm;
