import React from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";
import Cta from "../Cta";

import Container from "../Container";
import BasePadding from "../BasePadding";

import styles from "./Media.module.scss";

const fileType = file => { return file ? file.match(/\.[0-9a-z]+$/i) : null };

const Media = ({ addPadding, isPost, componentIndex, content: { primary }}) => {
	const mediaFileType = fileType(primary.media.name);

	return (
		primary.media?.url ?
			<div className={`
				${styles.media}
				${isPost ? styles.blogPost : ""}
				${(addPadding && componentIndex !== 0) ? styles.paddingTop : ""}
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
				${primary.remove_padding ? styles.removePadding : ""}
			`}>
				<>
					{isPost ?
						<BasePadding>
							{mediaFileType && mediaFileType[0] === ".mp4" &&
									<video autoPlay muted loop playsInline>
										<source src={primary.media.url} type="video/mp4" />
									</video>
							}
							{mediaFileType && mediaFileType[0] === ".mp3" &&
								<audio controls src={primary.media.url} />
							}
							{mediaFileType && (mediaFileType[0] === ".jpg" || mediaFileType[0] === ".png") &&
								<img src={primary.media.url} alt={primary.media?.alt} />
							}
							<div className={`${styles.text} ${styles[`align${primary.text_alignment}`]}`}>
								<PrismicRichText field={primary.text} />
							</div>
						</BasePadding>
						:
						<>
							{mediaFileType && mediaFileType[0] === ".mp4"
								?
									<video autoPlay muted loop playsInline>
										<source src={primary.media.url} type="video/mp4" />
									</video>
								:
								<img src={primary.media.url} alt={primary.media?.alt} />
							}
							{((primary.text.length && primary.text[0].text) || primary.cta_link?.url) && (
								<Container>
									<BasePadding>
										<div className={`${styles.text} ${styles[`align${primary.text_alignment}`]}`}>
											<PrismicRichText field={primary.text} />
										</div>
										{primary.cta_link?.slug &&
											<div className={styles.cta}>
												<Cta type="button" color={primary.background_color}>
													<Link to={`/${primary.cta_link.slug}`}>
														{primary.cta_text}
													</Link>
												</Cta>
											</div>
										}
										{primary.cta_link?.url &&
											<div className={styles.cta}>
												<Cta type="button" color={primary.background_color}>
													<a href={primary.cta_link.url}>
														{primary.cta_text}
													</a>
												</Cta>
											</div>
										}
									</BasePadding>
								</Container>
							)}
						</>
					}
				</>
			</div>
		:
		null
	);
}

export default Media;