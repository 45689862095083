import React from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";

import Eyebrow from "../Eyebrow";
import BasePadding from "../BasePadding";
import Cta from "../Cta";

import styles from "./CTABlock.module.scss";

const CTABlock = ({ addPadding, content: { primary, items }}) => {
	return (
		<div className={`
			${styles.ctaBlock}
			${addPadding ? styles.paddingTop : ""}
			${primary.image_alignment ? styles.imageAlignRight : styles.imageAlignLeft}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
				<div className={styles.textContainer}>
					<BasePadding>
						{primary.eyebrow[0]?.text && (
							<div className={styles.eyebrow}>
								<Eyebrow eyebrow={primary.eyebrow[0]?.text} />
							</div>
						)}
						<div className={styles.text}>
							<PrismicRichText field={primary.text1} />
							{primary.cta_link?.slug &&
								<div className={styles.cta}>
									<Cta type="button" color={primary.background_color}>
										<Link to={`/${primary.cta_link.slug}`}>
											{primary.cta_text}
										</Link>
									</Cta>
								</div>
							}
							{primary.cta_link?.url &&
								<div className={styles.cta}>
									<Cta type="button" color={primary.background_color}>
										<a href={primary.cta_link.url}>
											{primary.cta_text}
										</a>
									</Cta>
								</div>
							}
						</div>
					</BasePadding>
				</div>
				<div className={styles.imageContainer}>
					{items.length && items.map((item) => {
						if (!item.cta_image.url) {
							return (
								<Cta type="button">
									<a href={item.cta_link.url} target="_blank" rel="noopener noreferrer">
										{item.cta_text}
									</a>
								</Cta>
							)
						} else {
							return (
								<a key={item.id} href={item.cta_link.url} target="_blank" rel="noopener noreferrer">
									{item.cta_image.url &&
										<img src={item.cta_image.url} alt={item.cta_image.alt} height={item.image_height ? `${item.image_height}px` : "auto"} />
									}
									<>{item.cta_text}</>
								</a>
							)
						}
					})}
				</div>
		</div>
	);
};
export default CTABlock;
