import { useState, forwardRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { Modal, Backdrop } from "@mui/material";
import ReactGA from 'react-ga4';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { PrismicRichText } from '@prismicio/react';
import { useSpring, animated } from "react-spring";
import * as Select from '@radix-ui/react-select';
import { ChevronDownIcon, CheckIcon } from "@radix-ui/react-icons";
import BasePadding from '../BasePadding';
import { ReactComponent as CloseButton } from "../../resources/close-button.svg";

import formstyles from "../Form/Form.module.scss";
import styles from "./NewsletterPopup.module.scss";
import "../Form/selectstyles.scss";

const SelectItem = forwardRef(({ children, className, ...props }, forwardedRef) => {
	return (
	  <Select.Item className="SelectItem" {...props} ref={forwardedRef}>
		<Select.ItemText>{children}</Select.ItemText>
		<Select.ItemIndicator className="SelectItemIndicator">
		  <CheckIcon />
		</Select.ItemIndicator>
	  </Select.Item>
	);
});


const Fade = forwardRef((props, ref) => {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
	  from: { opacity: 0, transform: "translate3d(0,100%,0)" },
	  to: { opacity: open ? 1 : 0, transform: "translate3d(0,0,0)" },
	  onStart: () => {
		if (open && onEnter) {
		  onEnter();
		}
	  },
	  onRest: () => {
		if (!open && onExited) {
		  onExited();
		}
	  },
	});

	return (
	  <animated.div ref={ref} style={style} {...other}>
		{children}
	  </animated.div>
	);
});

const autoCompleteOptions = [
	"The Influencer",
	"Media Roundtable Podcast",
	"Other Podcast",
	"Linkedin (Post)",
	"Online article",
	"Newsletter",
	"Google or other search engine",
	"From a network partner",
	"I am a media vendor",
	"Another client referred me",
	"I worked at a previous client of Oxford Road",
	"Referral from Oxford Road team member",
	"Google (Ad)",
	"Linkedin (Ad)",
	"Friend/Colleague referral",
	"News/press",
	"Another ad agency"
];


const NewsletterPopup = ({ globalData, showPopup }) => {
	const [ showModal, setShowModal ] = useState(false);
	const [ formPart, setFormPart ] = useState(0);
	const [ shouldSubmit, setShouldSubmit ] = useState(true);
	const [ displayThanks, setDisplayThanks ] = useState(false);
	
	useEffect(() => {
		setShowModal(showPopup);
	}, [showPopup]);

	const formik = useFormik({
		initialValues: {
			role: 'advertiser',
			email: '',
			name: '',
			company: '',
			jobTitle: '',
			location: '',
			howDidYouHearAboutUs: '',
		},
		onSubmit: async (values) => {
			await fetch('https://usebasin.com/f/d21a434bc33d', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json; charset=UTF-8' },
				body: JSON.stringify(values)
			});
			if (!shouldSubmit) {
				setDisplayThanks(true);
			}
		},
	});

	if (sessionStorage.getItem('modal') === 'false') {
		return null;
	}

	if (showModal) {
		ReactGA.event({
			category: "modal_popup",
			action: "popup viewed",
			label: "newsletter",
		});	  
	}

	return (
		<Modal
			className={styles.modal}
			open={showModal}
			onClose={() => {
				if (formPart === 1 && shouldSubmit) {
					formik.handleSubmit();
				}
				sessionStorage.setItem('modal', 'false');
				setShowModal(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={showModal} className={styles.modalOuter}>
				<div className={styles.inner}>
					<button className={styles.closeButton} onClick={() => {sessionStorage.setItem('modal', 'false'); setShowModal(false); }}><CloseButton /></button>
					<div className={styles.modalContent}>
						<div className={styles.imageBlock}><img src={globalData.modal_image.url} alt={globalData.modal_image.alt} /></div>
						<div className={styles.text}>
							<BasePadding>
								<PrismicRichText field={globalData.modal_headline} />
								<PrismicRichText field={globalData.modal_copy} />
								<div className={styles.newsletterSignup}>
									{displayThanks ? (
										<h4>Thank you!</h4>
									) : (
									<form onSubmit={(e) => { e.preventDefault(); setShouldSubmit(false); formik.handleSubmit(); }}>
										<div style={{ width: '100%', height: formPart === 0 ? 'auto' : 0, visibility: formPart === 0 ? 'visible' : 'hidden'}}>
											<Stack width="100%" spacing={1.5} mb={1.5} className={formstyles.inputWrapper}>
												<div className={formstyles.selectForm}>
													<div className={formstyles.input}>
														<label className={formstyles.defaultLabel}>What is your role?</label>
															<Select.Root
																defaultValue={formik.values.role}
																onValueChange={item => formik.setFieldValue('role', item)}
															>
																<Select.Trigger className="SelectTrigger">
																	<Select.Value />
																	<Select.Icon className="SelectIcon">
																		<ChevronDownIcon />
																	</Select.Icon>
																</Select.Trigger>
																<Select.Portal style={{ zIndex: "9999" }} className="Portal">
																	<Select.Content className="SelectContent">
																		<Select.Viewport className="SelectViewport">
																			<SelectItem value="advertiser">Advertiser</SelectItem>
																			<SelectItem value="publisher">Publisher</SelectItem>
																			<SelectItem value="third-party-provider">Third-Party Provider</SelectItem>
																			<SelectItem value="podcast-creator">Podcast Creator</SelectItem>
																			<SelectItem value="other">Other</SelectItem>
																		</Select.Viewport>
																	</Select.Content>
																</Select.Portal>
															</Select.Root>
														</div>
													</div>
													<TextField
														type="email"
														id="email"
														name="email"
														label="EMAIL ADDRESS"
														required="required"
														value={formik.values.email}
														onChange={formik.handleChange}
													/>
													<TextField
														type="text"
														id="name"
														name="name"
														label="NAME"
														required="required"
														value={formik.values.name}
														onChange={formik.handleChange}
													/>
												</Stack>
											<button
												className={styles.next}
												type="button"
												disabled={!formik.values.email || !formik.values.name}
												onClick={() => setTimeout(() => setFormPart(1), 1000)}
											>Submit</button>
										</div>
										<div style={{ width: '100%', height: formPart === 1 ? 'auto' : 0, visibility: formPart === 1 ? 'visible' : 'hidden'}}>
											<Stack width="100%" spacing={1.5} mb={1.5} className={formstyles.inputWrapper}>
												<span>We'd love to learn more</span>
												<TextField
													type="text"
													id="company"
													name="company"
													label="COMPANY"
													value={formik.values.company}
													onChange={formik.handleChange}
												/>
												<TextField
													type="text"
													id="jobTitle"
													name="jobTitle"
													label="JOB TITLE"
													value={formik.values.jobTitle}
													onChange={formik.handleChange}
												/>
												<TextField
													type="text"
													id="location"
													name="location"
													label="LOCATION"
													value={formik.values.location}
													onChange={formik.handleChange}
												/>
												<Autocomplete
													id="how-did-you-hear-about-us"
													name="how-did-you-hear-about-us"
													required
													openOnFocus={false}
													freeSolo
													options={autoCompleteOptions.map(option => option)}
													onInputChange={(event, value) => formik.setFieldValue('howDidYouHearAboutUs', value)}
													renderInput={(params) => <TextField {...params} label="HOW DID YOU HEAR ABOUT US?" name="howDidYouHearAboutUs" value={formik.values.howDidYouHearAboutUs} onChange={formik.handleChange} />}
												/>
											</Stack>
											<input type="submit" />
										</div>
									</form>
									)}
								</div>
							</BasePadding>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	)
};

export default NewsletterPopup;