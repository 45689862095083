import React from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";

import Container from "../Container";
import Cta from "../Cta";
import BasePadding from "../BasePadding";

import styles from "./Tout.module.scss";
import Eyebrow from "../Eyebrow";

const Tout = ({ addPadding, lastItem, content: { primary }}) => {
	const fileType = primary.background_media?.name?.match(/\.[0-9a-z]+$/i);
	return (
		<div className={`
			${styles.tout}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			${primary.show_borders ? styles.addBorders : ""}
			${lastItem ? styles.removePaddingBottom : ""}
			${primary.add_bottom_spacing ? styles.addBottomSpacing: ""}
		`}>
			<div className={styles.background}>
				{fileType && fileType[0] === ".mp4"
					?
						<video autoPlay muted loop playsInline>
							<source src={primary.background_media.url} type="video/mp4" />
						</video>
					:
						<img src={primary.background_media.url} />
				}
			</div>
			<Container>
				<BasePadding>
					<>
						<div className={`${styles.overlay} ${primary.knockout_text ? styles.knockout : ""}`}>
							{primary.eyebrow &&
								<div className={styles.eyebrow}>
									<Eyebrow eyebrow={primary.eyebrow} />
								</div>
							}
							<div className={styles.heading}>
								<PrismicRichText field={primary.tout_title} />
							</div>
							<div className={styles.text}>
								<PrismicRichText field={primary.tout_text} />
							</div>
							{primary.cta_link?.slug &&
								<div className={styles.footer}>
									<Cta type="button" color={primary.background_color}>
										<Link to={`/${primary.cta_link.slug}`}>{primary.cta_text}</Link>
									</Cta>
								</div>
							}
							{primary.cta_link?.url &&
								<div className={styles.footer}>
									<Cta type="button" color={primary.background_color}>
										<a href={`/${primary.cta_link.url}`}>{primary.cta_text}</a>
									</Cta>
								</div>
							}
						</div>
					</>
				</BasePadding>
			</Container>

		</div>
	)
};

export default Tout;