import React from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";

import Container from "../Container";
import BasePadding from "../BasePadding";
import Eyebrow from "../Eyebrow";
import Cta from "../Cta";

import styles from "./MediaColumns.module.scss";

const MediaColumns = ({ addPadding, content: { primary, items }}) => (
	items ?
		<div className={`
			${styles.mediaColumns}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			${primary.number_of_columns ? styles.threeColumns : ""}
		`}>
			<Container>
				<BasePadding>
					<div className={styles.columns}>
						{items.map((item, index) => {
							const fileType = item.media_file.kind;
							return (
								<div className={styles.column} key={index}>
									{fileType === "image" && <img src={item.media_file.url} />}
									{fileType === "document" && <video autoPlay muted loop playsInline><source src={item.media_file.url} type="video/mp4" /></video>}
									<div className={styles.title}>
										<Eyebrow eyebrow={item.column_title[0].text} />
									</div>
									<div className={styles.text}>
										<PrismicRichText field={item.column_text} />
									</div>
								</div>
							)
						})}
					</div>
					{primary.cta_link?.slug &&
						<div className={styles.footer}>
							<Cta type="button" color={primary.background_color}>
								<Link to={`/${primary.cta_link.slug}`}>{primary.cta_text}</Link>
							</Cta>
						</div>
					}
					{primary.cta_link?.url &&
						<div className={styles.footer}>
							<Cta type="button" color={primary.background_color}>
								<a href={primary.cta_link.url}>{primary.cta_text}</a>
							</Cta>
						</div>
					}
				</BasePadding>
			</Container>
		</div>
		:
		null
);

export default MediaColumns;
