import React from "react";
import { PrismicRichText } from '@prismicio/react';
import { Link } from "react-router-dom";

import Eyebrow from "../Eyebrow";
import BasePadding from "../BasePadding";
import Container from "../Container";
import Cta from "../Cta";

import styles from "./FixedImageColumn.module.scss";

const fileType = file => { return file ? file.match(/\.[0-9a-z]+$/i) : null };

const FixedImageColumn = ({ addPadding, content: { primary }}) => {
	const mediaFileType = fileType(primary.media?.name);

	return (
		<div className={`
			${styles.fixedImageColumn}
			${addPadding ? styles.paddingTop : ""}
			${primary.image_alignment ? styles.imageAlignRight : styles.imageAlignLeft}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
			${primary.extra_large_image ? styles.extraLargeImage : ''}
		`}>
			<Container>
				<BasePadding>
					<>
						<div className={styles.fixedContainer}>
							<div className={styles.textContainer}>
								<div className={styles.eyebrow}>
									<Eyebrow eyebrow={primary.eyebrow} />
								</div>
								<div className={styles.text}>
									<PrismicRichText field={primary.copy} />
									{primary.cta_link?.slug &&
										<div className={styles.cta}>
											<Cta type="button" color={primary.background_color}>
												<Link to={`/${primary.cta_link.slug}`}>
													{primary.cta_text}
												</Link>
											</Cta>
										</div>
									}
									{primary.cta_link?.url &&
										<div className={styles.cta}>
											<Cta type="button" color={primary.background_color}>
												<a href={primary.cta_link.url}>
													{primary.cta_text}
												</a>
											</Cta>
											{primary.secondary_cta_link?.url && (
												<Cta type="button" color={primary.background_color}>
													<a href={primary.secondary_cta_link.url}>
														{primary.secondary_cta_text}
													</a>
												</Cta>
											)}

										</div>
									}
								</div>
							</div>
							<div className={styles.imageContainer}>
								{mediaFileType && mediaFileType[0] === ".mp4"
									?
										<video autoPlay muted loop playsInline>
											<source src={primary.media.url} type="video/mp4" />
										</video>
									:
										<img src={primary.media.url} alt={primary.media.alt} />
								}

							</div>
						</div>
					</>
				</BasePadding>
			</Container>
		</div>
	);
};
export default FixedImageColumn;