import React from "react";
import { PrismicRichText } from '@prismicio/react';
import BasePadding from "../BasePadding";
import Container from "../Container";
import Eyebrow from "../Eyebrow";

import styles from "./Columns.module.scss";
import { linkResolver } from "../../prismic-configuration";
import { ReactComponent as ArrowLeft } from "./resources/arrow-left.svg";


const SingleColumn = ({eyebrow, text, image, hasImage, itemdata}) => {
  return (
    <div className={hasImage ? styles.columnWithImage : styles.column}>
      <div className={styles.image}>
        <img src={image?.url} alt={'img'}/>
      </div>

      <div className={styles.text}>
        <PrismicRichText field={text}/>
      </div>

      <div className={styles.button_wrapper}>
        <a className={styles.button} href={linkResolver(itemdata?.cta_link1)}>
          <PrismicRichText field={itemdata?.cta_text1}/>
          <ArrowLeft />
        </a>
      </div>

    </div>
  )
};

const fileType = file => {
  return file ? file.match(/\.[0-9a-z]+$/i) : null
};

const Columns = ({addPadding, content: {primary, items}}) => {
  const mediaFileType = primary.media?.name ? fileType(primary.media.name) : null;
  return (
    <div className={`
			${styles.columns}
			${styles[`align${primary.align_text}`]}
			${styles.paddingTop}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
      <Container>
        <BasePadding>
          {primary.eyebrow &&
          <div className={styles.eyebrow}>
            <Eyebrow eyebrow={primary.eyebrow}/>
          </div>
          }

          <div
            className={`${primary.media?.url ? styles.wrapperWithImage : styles.wrapper} ${primary.number_of_columns ? styles.threeColumns : ""}`}>
            {primary.media?.url ?
              <>
                <SingleColumn hasImage eyebrow={items[0].eyebrow} text={items[0].text1}/>
                <div className={styles.columnWithImage}>
                  {mediaFileType && mediaFileType[0] === ".mp4"
                    ?
                    <video autoPlay muted loop playsInline>
                      <source src={primary.media.url} type="video/mp4"/>
                    </video>
                    :
                    <img src={primary.media.url} alt={primary.media.alt}/>
                  }
                </div>
              </>
              :
              <>
                {items?.map((item, index) => (
                  <SingleColumn key={index} eyebrow={item.eyebrow} text={item.text1} image={item.image} itemdata={item}/>
                ))}
              </>
            }
          </div>
        </BasePadding>
      </Container>
    </div>
  );
};

export default Columns;
