import React, { useEffect, useState } from "react";
import { client } from "../../prismic-configuration";
import * as Prismic from "@prismicio/client";
import moment from "moment";
import { PrismicRichText } from '@prismicio/react';

import styles from "./FeaturedPosts.module.scss";
import { Link } from "react-router-dom";
import Eyebrow from "../Eyebrow";
import Cta from "../Cta";
import Container from "../Container";
import BasePadding from "../BasePadding";

const FeaturedPosts = ({ addPadding, content: { primary }}) => {
	const [ podcastData, setPodcastData ] = useState(null);
	const [ postData, setPostData ] = useState(null);

	useEffect(() => {
		const fetchPosts = async () => {
			client.query([
				Prismic.filter.at("document.type", "post"),
				Prismic.filter.at("document.tags", ["Podcast"]),
			], { pageSize: 1, orderings : '[document.first_publication_date desc]' }).then(result => {
				setPodcastData(result.results);
			});
			client.query([
				Prismic.filter.at("document.type", "post"),
				Prismic.filter.not("document.tags", ["Podcast"]),
			], { pageSize: 1, orderings : '[document.first_publication_date desc]' }).then(result => {
				setPostData(result.results);
			});
		};
		fetchPosts();
	}, []);

	return (
		<div className={`
			${styles.posts}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					<div className={styles.postContainer}>
						{podcastData?.map((post, index) => (
							<div key={index} className={primary.display_featured_post ? styles.featuredPost : styles.post}>
								<div className={styles.eyebrow}>
									<Eyebrow eyebrow="Listen to our Latest Podcast" />
								</div>
								{post.data.post_thumbnail?.url &&
									<div className={styles.image}>
										<Link to={`/thought-leadership/${post.uid}`}>
											<img src={post.data.post_thumbnail.url} alt={post.data.post_thumbnail.alt} />
										</Link>
									</div>
								}
								<div className={styles.postEyebrow}>
									<Eyebrow eyebrow={moment(post?.data?.post_date ? post?.data?.post_date : post.last_publication_date).format("MMMM D, YYYY")} />
								</div>
								<div className={styles.postTitle}>
									<Link to={`/thought-leadership/${post.uid}`}>
										{post.data.post_title}
									</Link>
								</div>
								<div className={styles.postSummary}>
									<PrismicRichText field={post.data.post_summary} />
								</div>
								<div className={styles.postCta}>
									<Cta>
										<Link to={`/thought-leadership/${post.uid}`}>
											Read More
										</Link>
									</Cta>
								</div>
							</div>
						))}

						{postData?.map((post, index) => (
							<div key={index} className={primary.display_featured_post ? styles.featuredPost : styles.post}>
								<div className={styles.eyebrow}>
									<Eyebrow eyebrow="Read our Latest Blog Post" />
								</div>
								{post.data.post_thumbnail?.url &&
									<div className={styles.image}>
										<Link to={`/thought-leadership/${post.uid}`}>
											<img src={post.data.post_thumbnail.url} alt={post.data.post_thumbnail.alt} />
										</Link>
									</div>
								}
								<div className={styles.postEyebrow}>
									<Eyebrow eyebrow={moment(post?.data?.post_date ? post?.data?.post_date : post.last_publication_date).format("MMMM D, YYYY")} />
								</div>
								<div className={styles.postTitle}>
									<Link to={`/thought-leadership/${post.uid}`}>
										{post.data.post_title}
									</Link>
								</div>
								<div className={styles.postSummary}>
									<PrismicRichText field={post.data.post_summary} />
								</div>
								<div className={styles.postCta}>
									<Cta>
										<Link to={`/thought-leadership/${post.uid}`}>
											Read More
										</Link>
									</Cta>
								</div>
							</div>
						))}
					</div>
					{primary.cta_link?.slug &&
						<div className={styles.cta}>
							<Cta type="button" color={primary.background_color}>
								<Link to={`/${primary.cta_link.slug}`}>
									{primary.cta_text}
								</Link>
							</Cta>
						</div>
					}
					{primary.cta_link?.url &&
						<div className={styles.cta}>
							<Cta type="button" color={primary.background_color}>
								<a href={primary.cta_link.url}>
									{primary.cta_text}
								</a>
							</Cta>
						</div>
					}

				</BasePadding>
			</Container>
		</div>
	);
};

export default FeaturedPosts;
