import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { client } from "../../prismic-configuration";
import { useParams, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

import NotFound from "../NotFound";

import ComponentComposer from "../../components/ComponentComposer";
import SvgSquiggle from "../../components/SvgSquiggle";

import styles from "./Blog.module.scss";
import Eyebrow from "../../components/Eyebrow";
import Container from "../../components/Container";
import BasePadding from "../../components/BasePadding";

const Blog = () => {
	const params = useParams();
	const location = useLocation();
	const [ post, setPostData ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ notFound, toggleNotFound ] = useState(false);
	const prevLocation = useRef();

	const uid = params.uid;

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getByUID("post", uid);

			setIsLoading(true);
			toggleNotFound(false);
			if (result) {
				// If we have data, setData
				setIsLoading(false);
				setPostData(result);
			} else {
				// Otherwise show an error message
				console.warn('Page document not found. Make sure it exists.');
				setIsLoading(false);
				toggleNotFound(true);
			}
			if (prevLocation.current !== location.pathname) {
				// scroll to the top of the page if the url is different
				window.scrollTo(0, 0);
				prevLocation.current = location.pathname;
			}
			ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: document.title });
			return;
		};
		fetchData();
	}, [uid, location]); // Skip the Effect hook if the UID hasn't changed

	if (isLoading) {
		// Loading state
		return (
			<div className={styles.loading}><SvgSquiggle /></div>
		)
	}
	if (post && !notFound) {
		// Content for this slug has returned, render data
		return (
			<>
				<Helmet>
					<title>{post.data.post_title} | Oxford Road</title>
					<meta property="og:title" content={post.data.page_title} />
					<meta property="og:description" content={post.data.description ? post.data.description : ""} />
					<meta property="og:image" content={post.data.og_image?.url ? post.data.og_image?.url : ""} />
				</Helmet>
				<div className={styles.blogBody}>
				<Container>
					<div className={styles.blogHeader}>
						<BasePadding>
							<Eyebrow eyebrow={moment(post.data.post_date || post.first_publication_date).format("MMMM D, YYYY")} />
							<h1>{post.data.post_title}</h1>
						</BasePadding>
					</div>
				</Container>
				{post.data?.body &&
					<Container>
						<div className={styles.postBody}>
							{post.data?.body.map((item, index) => {
								const previousItem = post.data.body[index - 1];
								return (
									<ComponentComposer
										key={index}
										isPost={true}
										previousComponent={previousItem}
										component={item}
										index={index}
										lastItem={index + 1 === post.data.body.length}
									/>
								)
							})}
						</div>
					</Container>

				}
				</div>
			</>
		)
	} else {
		// There is no content for this slug
		return <NotFound />
	}
}

export default Blog;
