import React, { useEffect, useState } from "react";
import { client } from "../../prismic-configuration";
import * as Prismic from "@prismicio/client";
import moment from "moment";
import { PrismicRichText } from '@prismicio/react';

import styles from "./PostsArchive.module.scss";
import { Link } from "react-router-dom";
import Eyebrow from "../Eyebrow";
import Cta from "../Cta";
import Container from "../Container";
import BasePadding from "../BasePadding";

const PostsArchive = ({ addPadding, content: { primary }}) => {
	const [ selectedYear, setSelectedYear ] = useState(new Date().getFullYear());
	const [ postsData, setPostsData ] = useState([]);
	const [ yearsData, setYearsData ] = useState();
	const [ selectedMonth, setSelectedMonth ] = useState(null)
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	
	useEffect(() => {
		let posts = [];
		let years = new Set();
		const resultsPerPage = 100;
		const fetchPosts = async () => {
			const findTag = primary.tag?.tags?.find(tag => tag !== "Thought Leadership");
			client.query([
				Prismic.filter.at('document.type', 'post'),
				Prismic.filter.at('document.tags', findTag ? [findTag] : []),
			], { pageSize: resultsPerPage }).then(response => {
				posts = response.results;
				posts.sort((a, b) => (b.data?.post_date ? new Date(b.data?.post_date) : new Date(b.first_publication_date)) - (a.data?.post_date ? new Date(a.data?.post_date) : new Date(a.first_publication_date)));
				posts.forEach(item => {
					years.add(item.data?.post_date ? new Date(item.data.post_date).getFullYear() : new Date(item.first_publication_date).getFullYear());
				});
				years.add(new Date().getFullYear());
				setYearsData(Array.from(years));
				const getResults = () => {
					const arrayMap = Array.from({length: Math.ceil(response.total_results_size / resultsPerPage)}).map((_, index) =>
						client.query([
							Prismic.filter.at('document.type', 'post'),
							Prismic.filter.at('document.tags', findTag ? [findTag] : []),			
						], { pageSize: resultsPerPage, page: index + 1}).then(response => {
							return response.results;
						})
					);
					return Promise.all(arrayMap);
				}
				getResults().then(results => {
					setPostsData(results.flat());
				});
			});
		};
		fetchPosts();
	}, []);

	return (
		<div className={`
			${styles.posts}
			${addPadding ? styles.paddingTop : ""}
			${primary.background_color ? styles[`background${primary.background_color}`] : ""}
		`}>
			<Container>
				<BasePadding>
					{postsData.length &&
						<div className={styles.archiveHeader}>
							<h5>See posts from:</h5>
							{yearsData?.map((year, index) => (
								<button key={index} onClick={() => { setSelectedYear(year); setSelectedMonth(null);}} className={selectedYear === year ? styles.activeButton : styles.archiveButton}>{year}</button>
							))}
							<div className={styles.months}>
								{months.map((month, index) => (
									<button key={index} onClick={() => setSelectedMonth(index === selectedMonth ? null : index)} className={selectedMonth === index ? styles.activeButton : styles.archiveButton}>{month}</button>
								))}
							</div>
						</div>
					}
					<div className={styles.postContainer}>
						{postsData?.filter(item => {
							const postDate = item?.data.post_date ? item.data.post_date : item.first_publication_date;
							return selectedYear === new Date(postDate).getFullYear();
						}).filter(item => {
							const postDate = item?.data.post_date ? item.data.post_date : item.first_publication_date;
							return selectedMonth !== null ? selectedMonth === new Date(postDate).getUTCMonth() : item
						}).map((post, index) => (
							<div key={index} className={styles.post}>
								{post.data?.post_thumbnail?.url && 
									<div className={styles.image}>
										<Link to={`/thought-leadership/${post.uid}`}>
											<img src={post.data.post_thumbnail.url} alt={post.data.post_thumbnail.alt} />
										</Link>
									</div>
								}
								<div className={styles.postEyebrow}>
									<Eyebrow eyebrow={moment(post?.data?.post_date ? post?.data?.post_date : post.last_publication_date).format("MMMM D, YYYY")} />
								</div>
								<div className={styles.postTitle}>
									<Link to={`/thought-leadership/${post.uid}`}>
										{post.data?.post_title}
									</Link>
								</div>
								{!post.data?.post_thumbnail?.url &&
									<div className={styles.postSummary}>
										<PrismicRichText field={post.data?.post_summary} />
									</div>
								}
								<div className={styles.postCta}>
									<Cta>
										<Link to={`/thought-leadership/${post.uid}`}>
											Read More
										</Link>
									</Cta>
								</div>
							</div>
						))}
						{/* ugly, but need to display a message if no results are shown, the map above won't render if length is 0 */}
						{postsData?.filter(item => {
							const postDate = item?.data.post_date ? item.data.post_date : item.first_publication_date;
							return selectedYear === new Date(postDate).getFullYear();
						}).filter(item => {
							const postDate = item?.data.post_date ? item.data.post_date : item.first_publication_date;
							return selectedMonth !== null ? selectedMonth === new Date(postDate).getUTCMonth() : item
						}).length === 0 && <p className={styles.noPosts}>No posts were found</p>}
					</div>
				</BasePadding>
			</Container>
		</div>
	);
};

export default PostsArchive;