import React from "react";
import { PrismicRichText } from '@prismicio/react';
import ReactPlayer from 'react-player'

import BasePadding from "../BasePadding";
import Container from "../Container";
import styles from "./YoutubeEmbeds.module.scss";
import Eyebrow from "../Eyebrow";

const YoutubeEmbeds = ({ isPost, content: { primary }}) => {
	const sectionId = () => {
		if (primary.section_id) {
			return primary.section_id;
		}
		if (primary.text1) {
			return primary.text1[0]?.text.toLowerCase().replace(/\W/g,'-');
		}
		return '';
	}
	return (
		<div
			id={sectionId()}
			style={{ scrollMarginTop: '75px' }}
			className={`
				${primary.background_color ? styles[`background${primary.background_color}`] : ""}
				${styles.main_wrapper}
			`}>
			<Container>
			<BasePadding>
				{primary.text1[0]?.text && (
				<div className={styles.alignmentCentered}>
					<Eyebrow eyebrow={primary.eyebrow[0]?.text}/>
					<div className={styles.textModule}>
						<PrismicRichText field={primary.text1}/>
					</div>
					</div>
				)}
				<div className={`${styles.wrapper_cols} ${primary.youtube2?.embed_url ? styles.twoUp : undefined}`}>
				<div className={styles.wrapper_cols__item}>
					<div className={styles.video_box}>
					<ReactPlayer width='100%' height='100%' url={primary.youtube1.embed_url}/>
					</div>
					<div className={styles.wrapper_cols__item_note}>
					{primary.youtube1.title}
					</div>
				</div>
				{primary.youtube2?.embed_url && (
					<div className={styles.wrapper_cols__item}>
						<div className={styles.video_box}>
						<ReactPlayer width='100%' height='100%' url={primary.youtube2.embed_url}/>
						</div>
						<div className={styles.wrapper_cols__item_note}>
						{primary.youtube2.title}
						</div>
					</div>
				)}
				</div>
				{primary.youtube_channel_link.url && (
					<div className={styles.wrap_button}>
					<a className={styles.button} href={primary.youtube_channel_link.url} target={'_blank'}>
						{primary.youtube_channel_button_label}
					</a>
					</div>
				)}
			</BasePadding>
			</Container>
		</div>
		);
};

export default YoutubeEmbeds;
