import React, { forwardRef, useState } from "react";
import { useFormik } from "formik";
import { ChevronDownIcon, CheckIcon } from "@radix-ui/react-icons";
import * as Select from '@radix-ui/react-select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as Checkbox from '@radix-ui/react-checkbox';
import Stack from '@mui/material/Stack';
import qs from 'qs';
import Cta from "../Cta";

import styles from "./Hero.module.scss";
import formstyles from '../Form/Form.module.scss';

const SelectItem = forwardRef(({ children, className, ...props }, forwardedRef) => {
	return (
	  <Select.Item className="SelectItem" {...props} ref={forwardedRef}>
		<Select.ItemText>{children}</Select.ItemText>
		<Select.ItemIndicator className="SelectItemIndicator">
		  <CheckIcon />
		</Select.ItemIndicator>
	  </Select.Item>
	);
});

const autoCompleteOptions = [
	"The Influencer",
	"Media Roundtable Podcast",
	"Other Podcast",
	"Linkedin (Post)",
	"Online article",
	"Newsletter",
	"The Wall Street Journal",
	"Google or other search engine",
	"From a network partner",
	"I am a media vendor",
	"Another client referred me",
	"I worked at a previous client of Oxford Road",
	"Referral from Oxford Road team member",
	"Google (Ad)",
	"Linkedin (Ad)",
	"Friend/Colleague referral",
	"News/press",
	"Another ad agency"
];

export const GatedContentForm = (content_data) => {
	const params = qs.parse(location.search.slice(1));

	const formik = useFormik({
		initialValues: {
			role: 'podcast-creator',
			email: '',
			name: '',
			company: '',
			jobTitle: '',
			location: '',
			howDidYouHearAboutUs: '',
		},
	});

	return (
		<div className={`${formstyles.slimForm} ${styles.contentRight} ${styles.downloadForm} ${Object.keys(params).includes("redirect") ? styles.solidBackground : ''}`}>
			{Object.keys(params).includes("redirect") ? (
				<>
					<h4>Thanks! Here's your download.</h4>
					<Cta type="button">
						<a href={content_data?.pdf_download?.url} target="_blank" rel="noopener noreferrer" type="download">Download</a>
					</Cta>
				</>
			) : (
				<form action={`https://usebasin.com/f/${content_data.form_slug}`} method="POST">
					<Stack spacing={1.5} mb={1} className={styles.inputWrapper}>
						<Stack mb={1.5} spacing={1.5} className={formstyles.inputWrapper}>
						<div className={formstyles.input}>
							<label className={formstyles.defaultLabel}>What is your role?*</label>
								<Select.Root
									// defaultValue={formik.values.role}
									onValueChange={item => formik.setFieldValue('role', item)}
									id="role"
									name="role"
									required={true}
								>
									<Select.Trigger className="SelectTrigger">
										<Select.Value placeholder="Select your role" />
										<Select.Icon className="SelectIcon">
											<ChevronDownIcon />
										</Select.Icon>
									</Select.Trigger>
									<Select.Portal style={{ zIndex: "9999" }} className="Portal">
										<Select.Content className="SelectContent">
											<Select.Viewport className="SelectViewport">
												<SelectItem value="agency">Agency</SelectItem>
												<SelectItem value="podcast-creator">Podcast Creator</SelectItem>
												<SelectItem value="publisher">Publisher</SelectItem>
												<SelectItem value="third-party-provider">Third-Party Provider</SelectItem>
												<SelectItem value="advertiser">Advertiser/Brand</SelectItem>
												<SelectItem value="media">Media/Press</SelectItem>
												<SelectItem value="other">Other</SelectItem>
											</Select.Viewport>
										</Select.Content>
									</Select.Portal>
								</Select.Root>
							</div>
						</Stack>
						<TextField type="text" id="name" label="FULL NAME" name="name" required="required" />
						<TextField type="email" id="email" label="EMAIL ADDRESS" name="email" required="required" />
						<TextField type="text" id="company" label="COMPANY NAME" name="company" required="required" />
						<TextField type="text" id="title" label="JOB TITLE" name="job-title" />
						<div style={{ height: formik.values.role === 'advertiser' ? '4.25rem' : '0', transition: 'all 150ms ease', overflow: formik.values.role === 'advertiser' ? 'visible' : 'hidden', width: '100%'}}>
							<TextField type="text" id="budget" label="CURRENT ANNUAL AUDIO BUDGET" name="budget" style={{ width: '100%' }}/>
						</div>
						<Autocomplete
							id="how-did-you-hear-about-us"
							name="how-did-you-hear-about-us"
							required
							openOnFocus={false}
							freeSolo
							options={autoCompleteOptions.map(option => option)}
							renderInput={(params) => <TextField {...params} label="HOW DID YOU HEAR ABOUT US?" name="how-did-you-hear-about-us" />}
						/>
						<div className={formstyles.flex}>
							<Checkbox.Root className={formstyles.CheckboxRoot} defaultChecked id="newsletter-signup" name="newsletter-signup">
								<Checkbox.Indicator className={formstyles.CheckboxIndicator}>
									<CheckIcon />
								</Checkbox.Indicator>
							</Checkbox.Root>
							<label className={`${formstyles.Label} ${formstyles.defaultLabel}`} htmlFor="newsletter-signup">
								Sign up for our FREE weekly newsletter, The Influencer!
							</label>
						</div>
						{content_data?.form_disclosure_text && (
							<div className={formstyles.flex}>
								<p className={formstyles.disclaimerText}>{content_data?.form_disclosure_text}</p>
							</div>
						)}
					</Stack>
					<Cta type="button">
						<input type="submit" value={content_data?.form_cta_label || "Download Now"} />
					</Cta>
				</form>
			)}
		</div>
	)

};